export default [
  {
    path: '/engineers',
    name: 'engineers',
    component: () => import('@/views/engineers/Engineers.vue'),
    meta: {
      title: 'Engineers ',
      requiresAuth: false,
      action: 'browes',
      resource: 'Engineers',
      pageTitle: 'Engineers',
      breadcrumb: [
        {
          text: 'Engineers',
        },
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/engineers/details/:id',
    name: 'info-details-engineers',
    component: () => import('@/views/engineers/control-engineers/EngineersInfo.vue'),
    meta: {
      title: 'Details Engineers',
      requiresAuth: false,
      pageTitle: 'Engineers',
      breadcrumb: [
        {
          text: 'Engineers',
          to: '/engineers',
        },
        {
          text: 'show',
          active: true,
        },
      ],
    },
  },
  {
    path: '/engineers/add',
    name: 'add-engineers',
    component: () => import('@/views/engineers/control-engineers/AddEditEngineers.vue'),
    meta: {
      title: 'Add Engineers ',
      requiresAuth: false,
      action: 'create',
      resource: 'Engineers',
      pageTitle: 'Engineers',
      breadcrumb: [
        {
          text: 'Engineers',
          to: '/engineers',
        },
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/engineers/edit/:id',
    name: 'edit-engineers',
    component: () => import('@/views/engineers/control-engineers/AddEditEngineers.vue'),
    meta: {
      title: 'Edit Engineers',
      requiresAuth: false,
      pageTitle: 'Engineers',
      breadcrumb: [
        {
          text: 'Engineers',
          to: '/engineers',
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: "/engineers/work-certificates/:id",
  //   name: "work-certificates",
  //   component: () =>
  //     import("@/views/engineers/control-engineers/WorkCertificates.vue"),
  //   meta: {
  //     title: "Working Certificates",
  //     requiresAuth: false,
  //     pageTitle: "Working Certificates",
  //     breadcrumb: [
  //       {
  //         text: "Engineers",
  //         to: "/engineers"
  //       },
  //       {
  //         text: "Working Certificates",
  //         active: true
  //       }
  //     ]
  //   }
  // },
  // {
  //   path: '/engineers/certificates-status/:id',
  //   name: 'certificates-status',
  //   component: () => import('@/views/engineers/control-engineers/CertificatesStatus.vue'),
  //   meta: {
  //     title: 'Certificates Status',
  //     requiresAuth: false,
  //     pageTitle: 'Certificates Status',
  //     breadcrumb: [
  //       {
  //         text: 'Engineers',
  //         to: '/engineers',
  //       },
  //       {
  //         text: 'Certificates Status',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: '/engineers/certificate/:id',
    name: 'certificates-status-work',
    component: () => import(
      '@/views/engineers/control-engineers/StatusAndWorkCertificates.vue'
    ),
    meta: {
      title: 'Status & Permission &  Work Certificates',
      requiresAuth: false,
      pageTitle: 'Work & Status Certificates',
      breadcrumb: [
        {
          text: 'Engineers',
          to: '/engineers',
        },
        {
          text: 'Work & Status Certificates',
          active: true,
        },
      ],
    },
  },
]
